<template>
  <v-row>
    <v-dialog
      v-model="dialogOpen"
      scrollable
      persistent
    >
      <v-card
        tile
        class="note-detail"
      >
        <!-- This is the toolbar at the top of the detail dialog -->
        <v-app-bar
          flat
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="closeClicked"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Created by {{ note.username }} {{ timeStringConv(note.timestamp) }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <!-- The delete note button -->
            <v-btn
              v-if="!note.hidden"
              text
              @click="openDeleteDialog"
            >
              <v-icon class="pr-1">
                mdi-delete
              </v-icon>
              Delete
            </v-btn>
            <v-btn
              v-else
              text
              @click="requestNoteRestore"
            >
              <v-icon class="pr-1">
                mdi-delete-restore
              </v-icon>
              Restore
            </v-btn>

            <!-- The refresh button -->
            <v-btn
              dark
              text
              :loading="loading"
              @click="refreshClicked"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>
        <!-- This is the content of the dialog -->
        <v-card-text>
          <v-row>
            <!-- This is the content text of the note -->
            <v-col
              cols="12"
              class="pb-2 note-detail-text"
            >
              <v-card>
                <v-app-bar
                  flat
                  color="secondary"
                >
                  <v-toolbar-title>{{ note.subject }}</v-toolbar-title>
                </v-app-bar>
                <v-card-text>
                  <span>{{ note.content }}</span>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- This is the preview thumbnails grid -->
            <v-col
              cols="12"
              class="note-detail-files"
            >
              <v-card>
                <v-app-bar
                  flat
                  color="secondary"
                >
                  <v-toolbar-title>Files</v-toolbar-title>
                </v-app-bar>
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col
                        v-for="file in note.files"
                        :key="file.id"
                        cols="6"
                        sm="4"
                        md="2"
                        lg="2"
                        xl="1"
                      >
                        <v-card
                          class="ma-1"
                          hover
                          ripple
                          @click.native="openFilePreview(file)"
                        >
                          <v-img
                            height="192px"
                            :src="formImageURL(file.filename, 'thumbnail')"
                          >
                            <v-container
                              class="fill-height"

                              fluid
                            >
                              <v-row class="fill-height">
                                <v-col
                                  offset="5"
                                  cols="1"
                                >
                                  <v-icon color="black">
                                    {{ file.type !== 'other' ? 'mdi-magnify-plus-outline' : 'mdi-download' }}
                                  </v-icon>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-img>

                          <v-card-title primary-title>
                            <div>
                              {{ file.name.substring(0, file.name.lastIndexOf('.')) }}
                            </div>
                          </v-card-title>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <v-card
                          flat
                          tile
                          class="pt-1"
                        >
                          <vue2-dropzone
                            id="dz"
                            ref="dropzone"
                            :options="dropzoneOptions"
                            @vdropzone-success="uploadSuccess"
                            @vdropzone-error="uploadFailure"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- This is the file preview dialog -->
    <v-dialog
      v-model="filePreviewOpen"
      :width="filePreviewWidth"
      eager
    >
      <v-card>
        <v-app-bar
          flat
          dark
          color="accent"
        >
          <v-btn
            icon
            dark
            @click="filePreviewOpen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ filePreviewImageName }}</v-toolbar-title>
        </v-app-bar>
        <v-responsive>
          <v-img
            ref="note_file_preview"
            :src="filePreviewImageURL"
            style="
              width:auto;
              height:auto;
              max-height: 80vh;
            "
          />
        </v-responsive>
      </v-card>
    </v-dialog>

    <!-- This is the note delete dialog -->
    <v-dialog
      v-model="deleteNoteDialog"
      max-width="500px"
      persistent
    >
      <v-card
        tile
        class="popup-dialog"
      >
        <v-app-bar
          flat
          dark
          color="warning"
        >
          <v-toolbar-title>Are you sure you want to delete note {{ note.id }}?</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <div>
            Something something Simon should write this.
            Just be careful with deleting notes OK?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            text
            rounded
            :disabled="loading"
            @click="requestNoteDelete"
          >
            Delete
          </v-btn>
          <v-btn
            text
            rounded
            @click="deleteNoteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { EventBus } from '../scripts/event_bus'

export default {
  name: 'NoteDetailDialog',
  components: {
    vue2Dropzone: () => import('vue2-dropzone')
  },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    note: {
      type: Object,
      required: true,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      filePreviewOpen: false,
      filePreviewImageName: '',
      filePreviewImageURL: '',
      filePreviewWidth: 'auto',
      dropzoneOptions: {
        url: 'https://cmsdb.darkcosmos.org/files/uploadnote.php' + '?apikey=' + localStorage.getItem('devAPIKey'),
        timeout: 300000,
        maxFilesize: 200,
        maxFiles: 20,
        ignoreHiddenFiles: true,
        autoProcessQueue: true,
        autoQueue: true,
        addRemoveLinks: true,
        paramName: 'note_file',
        params: { note_id: this.note.id, action: 'save' },
        headers: {
          'Cache-Control': ''
        }
      },
      deleteNoteDialog: false,
      dialogLoading: false
    }
  },
  watch: {
    note: function (newNote, oldNote) {
      // console.log('The note was just updated, old id:', oldNote.id, ', new id:', newNote.id)
      this.dropzoneOptions.params.note_id = newNote.id
    }
    // dialogOpen: function (now, prev) {
    //   if (now) {
    //     console.log('Note detail opened for note with id', this.note.id)
    //   } else if (prev) {
    //     console.log('Note detail closed for note with id', this.note.id)
    //   }
    // }
  },
  // mounted () {
  //   console.log('Note ID dialog loaded')
  // },
  methods: {
    closeClicked: function () {
      this.$emit('closeClicked')
    },
    refreshClicked: function () {
      this.$emit('refreshClicked')
    },
    timeStringConv: function (timestamp) {
      const rightNow = moment()

      return moment.unix(timestamp).calendar(rightNow)
    },
    openDeleteDialog: function () {
      this.deleteNoteDialog = true
    },
    requestNoteDelete: function () {
      console.log('Requested to delete note', this.note.id)

      // this.postToDeleteScript(true) TODO: This isn't ready yet
    },
    requestNoteRestore: function () {
      console.log('Requested to restore note', this.note.id)

      // this.postToDeleteScript(false) TODO: This isn't ready yet
    },
    postToDeleteScript: function (hide) {
      // Only attempt a delete if the main page isn't loading, and the dialog isn't loading
      if (!this.loading && !this.dialogLoading) {
        this.dialogLoading = true

        this.$root.$data.si.postNoteDelete([
          { key: 'exp_id', value: this.note.id },
          { key: 'action', value: (hide ? 'hide' : 'unhide') }
        ], response => {
          this.dialogLoading = false

          // Dev stuff
          console.log(response)

          if (Number(response.success) === 1 && response.action === 'done') {
            // Tell the user it was a success
            EventBus.$emit('info', ('Note ' + this.note.id + ' was ') + (hide ? 'deleted' : 'restored') + ' successfully.')
            // Close the dialogs if deleting
            if (hide) {
              this.deleteNoteDialog = false
              this.closeClicked()
            }
            // Refresh the data either way
            this.refreshClicked()
          } else {
            console.log('Could not ' + (hide ? 'delete' : 'restore') + ' note id', this.note.id)

            this.deleteNoteDialog = false
            EventBus.$emit('error', {
              title: 'Could not ' + (hide ? 'delete' : 'restore'),
              message: response.success ? response.error_message : 'The note with id ' + this.note.id + (hide ? ' has already been deleted' : ' is not deleted')
            })
          }
        })
      }
    },
    openFilePreview: function (file) {
      if (file.type !== 'other') {
        this.filePreviewImageURL = this.formImageURL(file.filename, 'preview')
        this.filePreviewImageName = file.name.substring(0, file.name.lastIndexOf('.'))

        // Get the image element from the html part
        const imgElement = this.$refs.note_file_preview

        // Check to see if the image has loaded
        if (imgElement.naturalWidth === 0) {
          // This means the image is not loaded yet, so reset the width of the dialog
          this.filePreviewWidth = 0
          // Then update the width after the image has loaded.
          imgElement.onload = () => {
            // Set the dialog width to match the width of the image
            this.filePreviewWidth = imgElement.naturalWidth * 0.8
            // Open the dialog
            this.filePreviewOpen = true
          }
        } else {
          // If the image is already loaded, then just open the dialog
          this.filePreviewOpen = true
        }
      } else {
        console.log('This file type does not have a preview')
      }
    },
    uploadSuccess: function (file, response) {
      // Remove the file that was successfully uploaded
      this.$refs.dropzone.removeFile(file)
      // If there are no more files, show the user that the upload was a success
      if (!this.hasFiles()) {
        // Dev stuff
        console.log('Note files uploaded successfully.')
        console.log('Servers upload response: ' + (response || 'Zilch, nada, nothing. Weird..?'))
        // On a successful upload of all the files, refresh the data
        // Also show a success message to the user
        EventBus.$emit('info', 'Files uploaded successfully')
      } else {
        // This is if a single upload succeeded, but there are still more to go
        console.log('Uploaded file: ' + file)
      }
      // Refresh after each file, so that they populate the thumbnail grid as they upload.
      this.refreshClicked()
    },
    uploadFailure: function (file, message, xhr) {
      console.log('Note file failed to upload, note id:', this.note.id)
      console.log('The set param id was', this.dropzoneOptions.params.note_id)
      console.log('Servers upload error message was: ' + message)
      EventBus.$emit('error', {
        title: 'File upload failed',
        message: message
      })
    },
    hasFiles: function () {
      return this.$refs.dropzone.getQueuedFiles().length > 0
    },
    dropzoneUpload: function () {
      this.$refs.dropzone.processQueue()
    },
    resetDropzone: function () {
      this.$refs.dropzone.removeAllFiles()
    },
    formImageURL: function (filename, version) {
      return 'https://cmsdb.darkcosmos.org/files/viewnote.php?file=' + filename + '&version=' + version + '&apikey=' + localStorage.getItem('devAPIKey')
    }
  }
}
</script>

<style scoped>

</style>
